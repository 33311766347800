@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&?family=Roboto:wght@100;300;400;500;700;900&family=Syncopate:wght@400;700&family=Raleway:wght@200;300;400;500;700;900&family=Fredoka+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #6366F1;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}
 
@layer base {
  :root {
    --background: 258,87%,3%;
    --foreground: 222.2 84% 4.9%;
    --navButtonBacground: 222.2 84% 4.9%;
    --defaultBackground: #7620ff;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --muted: 210 40% 96.1%;
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-[15px] text-foreground font-Raleway bg-gray-100;
  }
}